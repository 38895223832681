$('.hero-slider').slick({
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1300,
    dots: true
});

$('.kamer-slider').slick({
    infinite: true,
    arrows: true,
    nextArrow: '<div class="next"> </div>',
    prevArrow: '<div class="prev"> </div>',
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 992,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        }
    }]
});
