$(document).ready(function() {

    $(".navigation").mmenu({
        "extensions": [
            "pagedim-black", "border-none"
        ],
        "navbar": false,
        "navbars": [ 
        {
            "position": "bottom",
            "content": [
                '  <div class="mobile-fb fb-like" data-href="https://www.facebook.com/Loreto-hotel-207415889295945/?fref=ts" data-layout="button_count" data-action="like" data-size="small" data-show-faces="false" data-share="false"></div>',
                "<a href='https://reservations.cubilis.eu/loreto-hotel-brugge/' target='_blank' class='menu-boekBtn'><p>Boek kamer</p></a>"
            ]
        }],
        "offCanvas": {
            "position": "right" 
        }
    }, {
        "clone": true
    });
    var API = $(".navigation").data("mmenu");
    $(".navbar-close").click(function() {
        API.close();
    });
});
