require("./plugins/bootstrap.js");
require("./plugins/bootstrap-datepicker.js");
require("./modules/fastbooker.js");
require("./plugins/slick.js");
require("./plugins/jquery.mmenu.all.min.js");
require("./plugins/jquery.raty.min.js");
require("./plugins/jPages.js");
require('./plugins/magnific-popup.js');

//require("./plugins/magnific-popup.js");
//require("./modules/dropdown.js");

require("./modules/forms.js");
require('./modules/pictures.js');
require('./modules/slider.js');
require('./modules/review.js');
require('./modules/mobilemenu.js');
require('./modules/slideOut-booker.js');
 
if ($('#maps').length){
require("./modules/location.js");
}

$('nav ul li:has(ul)').addClass('hasSubmenu');