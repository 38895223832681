
var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyDNn-SwX9kuLPetlLabTKGABDc2LnU-QBs';

(function() {
    if ($('#maps').length) {

        gmaps.load(function(google) {
            var el = document.getElementById('maps');
        var lat = 51.202952,
                long = 3.225155;

                 var styles = [{
                stylers: [
                    { saturation: -100 }
                ]
            }];

            var center = new google.maps.LatLng(lat, long);
            var icon = {
                   url: '/bundles/sitefrontend/img/logo.png',
                scaledSize: new google.maps.Size(82.5, 87.5)
            };

            var options = {
                zoom: 16,
                center: center,
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
                }
            };
 var styledMap = new google.maps.StyledMapType(styles, { name: "Hotel Loreto" });
            var map = new google.maps.Map(el, options);

            google.maps.event.addDomListener(window, "resize", function() {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });

            var marker = new google.maps.Marker({
                position: center,
                map: map,
                icon: icon
            });

            map.mapTypes.set('map_style', styledMap);
            map.setMapTypeId('map_style');
        });
    }
})();
