( function( d ) {
  var mL = [ 'Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December' ];
  var mS = [ 'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dec' ];

  d.prototype.getLongMonth = d.getLongMonth = function getLongMonth( inMonth ) {
    return gM.call( this, inMonth, mL );
  }

  d.prototype.getShortMonth = d.getShortMonth = function getShortMonth( inMonth ) {
    return gM.call( this, inMonth, mS );
  }

  function gM( inMonth, arr ) {
    var m;

    if ( this instanceof d ) {
      m = this.getMonth();
    } else if ( typeof inMonth !== 'undefined' ) {
      m = parseInt( inMonth, 10 ) - 1; // Subtract 1 to start January at zero
    }

    return arr[ m ];
  }
} )( Date );

( function( $ ) {

  //Header Fastbooker
  var d = new Date( new Date().getTime() );
  var t = new Date( new Date().getTime() + 24 * 60 * 60 * 1000 );
  var today = d.getDate() + "/" + ( '0' + ( d.getMonth() + 1 ) ).slice( -2 ) + "/" + d.getFullYear();
  var tomorrow = t.getDate() + "/" + ( '0' + ( t.getMonth() + 1 ) ).slice( -2 ) + "/" + t.getFullYear();


  var arrival = $( '#arrival' );
  var departure = $( '#departure' );
  var discount = $( '#discount' )


  arrival.datepicker( {
    format: 'dd/mm/yyyy',
    autoclose: true,
    startDate: 'dd/mm/yyyy',
    weekStart: 1,
    orientation: 'bottom',
    container: '.fastbooker__form',
  } ).on( 'changeDate', function( e ) {

    departure.focus();
    var selectedDate = arrival.datepicker( 'getDate' );
    var arrDay = arrival.val();

    $( '#arrival-month' ).html( selectedDate.getLongMonth() );
    var nextDay = new Date( selectedDate );
    nextDay.setDate( nextDay.getDate() + 1 );
    departure.datepicker( 'setDate', nextDay );
    departure.datepicker( 'setStartDate', nextDay );
  } )

  departure.datepicker( {
    format: 'dd/mm/yyyy',
    autoclose: true,
    startDate: tomorrow,
    weekStart: 1,
    orientation: 'bottom',
    container: '.fastbooker__form',
  } ).on( "changeDate", function( e ) {

    var selectedDeparture = departure.datepicker( 'getDate' );
    var depDay = departure.val();

    $( '#departure-month' ).html( selectedDeparture.getLongMonth() );

  } );

  var btn = $( '.js-fastbooker-btn' );

  var locale = $( 'html' ).attr( 'lang' );

  btn.click( function( e ) {
    e.preventDefault();
    var arrival = $( '#arrival' ).val();
    arrival = arrival.split( '/' );
    arrival = arrival[ 2 ] + '-' + arrival[ 1 ] + '-' + arrival[ 0 ];
    var departure = $( '#departure' ).val();
    departure = departure.split( '/' );
    departure = departure[ 2 ] + '-' + departure[ 1 ] + '-' + departure[ 0 ];
    var disco = $( '#discount' ).val();

    var href = "https://reservations.cubilis.eu/loreto-hotel-brugge";
    if ( ( arrival !== 'undefined-undefined-' ) && ( departure !== 'undefined-undefined-' ) ) {
      href = "https://reservations.cubilis.eu/loreto-hotel-brugge/Rooms/Select?" + "Arrival=" + arrival + "&Departure=" + departure + getLangCode( locale ) + "&DiscountCode=" + disco;;
    }

    window.open( href, '_blank' );
  } );


  function getLangCode( locale ) {
    var langCode = {
      'nl': 'nl-BE',
      'fr': 'fr-BE',
      'en': 'en-GB',
      'de': 'de-DE',
    }[ locale ];
    return '&Language=' + langCode;
  }

} )( jQuery );
