if ($('.js-fastbooker').length) {
    var stickyOffset = $('.js-fastbooker').offset().top;
    $(window).scroll(function() {
        var sticky = $('.js-fastbooker')
            scroll = $(window).scrollTop();
        if (scroll >= stickyOffset) {
            sticky.addClass('fixed');
        } else {
            sticky.removeClass('fixed');

        }
    });

    $('.js-fb-tab').on('click', function(e) {
        e.preventDefault();
      $('.js-fastbooker').toggleClass('isOpen');

    });


}
$('.closeBtn').on('click', function(event) {
    event.preventDefault();
    $('.js-fb-tab').css('display', 'inline-block');
    $('.slideOut').slideToggle().removeClass('opened');

});

$(document).mouseup(function(e)
{
    var container = $(".js-fastbooker");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0)
    {
        $('.js-fastbooker').removeClass('isOpen');
    }
});
