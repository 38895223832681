//var raty = require('raty-js');

(function () {

    var targetReviews = Routing.generate('site_frontend_ajax_call_reviews');
    var fetchReviews = Routing.generate('site_frontend_ajax_fetch_reviews');

// Rating
    $('#revRate').raty({
        path: '/bundles/sitefrontend/img/rating',
        width: false,
        starOff: 'star-off.png',
        starOn: 'star-on.png',
        starHalf: 'star-half.png'
    });

    var txt = $('.rating-caption');
    var img = $('#revRate img');
    var rate = $('#revRate');

    var $bad;
    var $poor;
    var $regular;
    var $good;
    var $amazing;
    var $click;

    getLanguage();

    $('.rating-caption').text($click);

    img.hover(function () {
        var rating = $(this).attr('title');
        var ratingtxt = getRating(rating);
        txt.removeClass('placeholder');
        txt.text(ratingtxt);
    });

    img.click(function () {
        img.removeClass('selected');
        $(this).addClass('selected');
    });

    rate.mouseleave(function () {
        var rating = $('.selected').attr('title');
        var ratingtxt = getRating(rating);
        if (rating) {
            txt.text(ratingtxt);
        } else {
            txt.addClass('placeholder');
            txt.text($click);
        }
    });

    function getLanguage() {
        $lang = $('html').attr('lang');
        switch ($lang) {
            case 'nl':
                $bad = 'vreselijk';
                $poor = 'matig';
                $regular = 'gemiddeld';
                $good = 'heel goed';
                $amazing = 'uitstekend';
                $click = 'klik om te beoordelen';
                break;
            case 'fr':
                $bad = 'mal';
                $poor = 'pauvre';
                $regular = 'ordinaire';
                $good = 'bien';
                $amazing = 'magnifique';
                $click = 'cliquez pour évaluer';
                break;
            case 'de':
                $bad = 'schlecht';
                $poor = 'mäßig';
                $regular = 'durchschnitt';
                $good = 'gut';
                $amazing = 'ausgezeichnet';
                $click = 'klicken um zu bewerten';
                break;
            case 'es':
                $bad = 'bad';
                $poor = 'poor';
                $regular = 'regular';
                $good = 'good';
                $amazing = 'amazing';
                $click = 'Click para evaluar';
                break;
            default:
                $bad = 'bad';
                $poor = 'poor';
                $regular = 'regular';
                $good = 'good';
                $amazing = 'amazing';
                $click = 'click to rate'
        }
    }

    function getRating(rating) {
        switch (rating) {
            case 'bad':
                var ratingtxt = $bad;
                break;
            case 'poor':
                var ratingtxt = $poor;
                break;
            case 'regular':
                var ratingtxt = $regular;
                break;
            case 'good':
                var ratingtxt = $good;
                break;
            case 'amazing':
                var ratingtxt = $amazing;
                break;
            default:
                var ratingtxt = '';
        }

        return ratingtxt;
    }

    // fetch reviews
    $.ajax({
        url: fetchReviews,
        type: "GET",
        dataType: 'json',
        success: function (data) {
            if (data.status == 'succes') {
              createReviews(data);
            } else {

            }
        }
    });



    function createReviews(data){
      var box = $('.reviews-box');
      $('.loading').remove();




      data.reviews.map(function(review){

     date = review.Creationdate;
     Cdate = date.substring(0,10);

        var html = '<div class=" isLoaded reviewed-item">';
            html += '<div class="reviewed-item-header">';
            html += '<h2>' + review.Naam + '<span class="datum"> ('+ Cdate +')</span></h2>';
            html += '<div class="rated"   style="width:140px"  data-score="' + review.Score + '"></div>';
            html += '</div>';
            html += '<div class="streepje"></div>';

            html += '<div  class="review-text"><p>' + review.Opmerkingen + '</p></div>';
            html += '</div>';
        box.append(html);
      });
      box.append('<a href="" class="btn btn-showmore js-showmore">More ...</a>');
      // Rating
      $('.rated').raty({
          path: '/bundles/sitefrontend/img/rating',
          readOnly: true,
          width: false,
          score: function () {
              return $(this).attr('data-score') / 2;
          },
          starOff: 'star-off.png',
          starOn: 'star-on.png',
          starHalf: 'star-half.png'
      });




      var average = 0;
      var total = 0;
      var score = 0;
      var $average = $('.average');
      var $total = $('.total');

      $('.rated').each(function() {
          score += parseInt($(this).attr('data-score'));
          total++;
      });

      average = (Math.round(score / total)) / 2;
      $average.attr('data-score', average);
      $average.raty({
          path: '/bundles/sitefrontend/img/rating',
          starHalf: 'star-half.png',
          starOff: 'star-off.png',
          starOn: 'star-on.png',
          half: true,
          readOnly: true,
          width: false,
          score: function() {
              return $(this).attr('data-score');
          }
      });

      $total.text(total + ' reviews');





      $('.isLoaded:lt(10)').removeClass('isLoaded');
      $('.js-showmore').on('click', function(e){
        e.preventDefault();
        $('.isLoaded:lt(10)').removeClass('isLoaded');
        if($('.isLoaded').size() == 0){
          $('.js-showmore').remove();
        }
      });
    }



    function isValidEmailAddress(emailAddress) {
        var pattern = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
        return pattern.test(emailAddress);
    }
    ;

    $('#review__submit').on('click', function () {
        if ($('[name="naam"]').val() === '' || $('[name="email"]').val() === '' || $('#revRemark').val() === '') {
            $('#errorContainer').addClass('isVisible');
        } else {
            if ($('[name="score"]').val() === '') {
                $('#errorContainer').addClass('isVisible');
            } else if (!isValidEmailAddress($('[name="email"]').val())) {
                $('#errorContainer').addClass('isVisible');
            } else {
                $.ajax({
                    url: targetReviews,
                    data: {
                        name: $('[name="naam"]').val(),
                        email: $('[name="email"]').val(),
                        rating: $('[name="score"]').val() * 2,
                        message: $('#revRemark').val()
                    },
                    type: "POST",
                    dataType: 'json',
                    success: function (data) {
                        if (data.status == 'succes') {

                            $('#addRev').fadeOut(500, function () {
                                $('#addRev').remove();
                            });
                            $('#errorContainer').addClass('isVisible');
                        } else {
                            $('#errorContainer').addClass('isVisible');
                        }
                    }
                });
            }
        }
        return false;
    });

})();
